<template>
    <div>
        <div v-if="!isLoading" class="bg-gray-100 overflow-hidden h-full">
            <div class="m-8 pb-8 bg-white rounded-xl shadow-xl">
                <div class="pt-5 flex justify-between">
                    <h2
                        class="max-w-6xl ml-2 lg:-mx-5 mx-auto px-4 text-lg leading-6 text-left font-bold text-black sm:px-6 lg:px-8"
                    >
                        {{ $t("bookings.booking_records") }}
                    </h2>
                    <button
                        v-if="isFileReady"
                        type="button"
                        class="mr-5 px-6 py-1 border border-gray-400 text-gray-500 rounded-md hover:text-gray-00"
                        @click="downloadCSVData"
                    >
                        {{ $t("bookings.export_csv") }}
                    </button>
                </div>
                <div class="lg:flex block">
                    <div
                        class="lg:mt-10 lg:ml-4 lg:mx-5 w-full p-4 lg:p-0 lg:w-1/4"
                    >
                        <label
                            for="venue"
                            class="block ml-3 text-sm font-medium text-gray-700 text-left"
                            >{{ $t("bookings.venue") }}</label
                        >
                        <input
                            name="venue"
                            @input="updateVenue($event.target.value)"
                            id="venue"
                            ref="venue"
                            class="mt-1 block border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                        <!--                    <datalist id="venueList">-->
                        <!--                        <option-->
                        <!--                            v-for="(venue, index) in getVenue"-->
                        <!--                            :value="venue"-->
                        <!--                            :key="index"-->
                        <!--                            >{{ venue }}</option-->
                        <!--                        >-->
                        <!--                    </datalist>-->
                    </div>
                    <div
                        class="lg:mt-10 lg:ml-4 lg:mx-5 w-full p-4 lg:p-0 lg:w-1/4"
                    >
                        <label
                            for="user_name"
                            class="block ml-3 text-sm font-medium text-gray-700 text-left"
                            >{{ $t("bookings.username") }}</label
                        >
                        <input
                            type="text"
                            name="user_name"
                            id="user_name"
                            ref="user"
                            @input="updateUser($event.target.value)"
                            @keypress="isLetter($event)"
                            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                        <!--                    <datalist id="users">-->
                        <!--                        <option-->
                        <!--                            v-for="(user, index) in getUser"-->
                        <!--                            :value="user"-->
                        <!--                            :key="index"-->
                        <!--                            >{{ user }}</option-->
                        <!--                        >-->
                        <!--                    </datalist>-->
                    </div>

                    <div
                        v-if="isDatepicker"
                        class="lg:mt-10 font-medium text-xl"
                    >
                        <span
                            class="text-sm flex lg:inline-block ml-4 text-gray-700"
                            >{{ $t("bookings.start_date") }}</span
                        >
                        <!--                                    <input-->
                        <!--                                        type="date"-->
                        <!--                                        v-model="startDate"-->
                        <!--                                        @change="updateFilter"-->
                        <!--                                        class="-->
                        <!--                                            border-b border-gray-400-->
                        <!--                                            w-full-->
                        <!--                                            focus:outline-none-->
                        <!--                                        "-->
                        <!--                                    />-->
                        <div
                            class="
                            ml-4
                                            font-normal
                                            text-base
                                            flex
                                            items-center
                                        "
                        >
                            {{ startDate }}
                            <button class="ml-3" @click="startCalendar = true">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-calendar
                                                "
                                    width="28"
                                    height="28"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#000000"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <rect
                                        x="4"
                                        y="5"
                                        width="16"
                                        height="16"
                                        rx="2"
                                    />
                                    <line x1="16" y1="3" x2="16" y2="7" />
                                    <line x1="8" y1="3" x2="8" y2="7" />
                                    <line x1="4" y1="11" x2="20" y2="11" />
                                    <line x1="11" y1="15" x2="12" y2="15" />
                                    <line x1="12" y1="15" x2="12" y2="18" />
                                </svg>
                            </button>
                        </div>
                        <CalendarModal
                            v-if="startCalendar"
                            :sel-date="startDate"
                            @onSelect="selectStartDate"
                            @onCancel="startCalendar = false"
                        />
                    </div>
                    <div
                        v-if="isDatepicker"
                        class="lg:mt-10 lg:mx-2 font-medium text-xl"
                    >
                        <span
                            class="text-sm flex lg:inline-block ml-4 lg:ml-0 text-gray-700"
                            >{{ $t("bookings.end_date") }}</span
                        >
                        <div
                            class="
                            ml-4
                            lg:ml-0
                                            font-normal
                                            text-base
                                            flex
                                            items-center
                                        "
                        >
                            {{ endDate }}
                            <button class="ml-3" @click="endCalendar = true">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-calendar
                                                "
                                    width="28"
                                    height="28"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#000000"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <rect
                                        x="4"
                                        y="5"
                                        width="16"
                                        height="16"
                                        rx="2"
                                    />
                                    <line x1="16" y1="3" x2="16" y2="7" />
                                    <line x1="8" y1="3" x2="8" y2="7" />
                                    <line x1="4" y1="11" x2="20" y2="11" />
                                    <line x1="11" y1="15" x2="12" y2="15" />
                                    <line x1="12" y1="15" x2="12" y2="18" />
                                </svg>
                            </button>
                        </div>
                        <CalendarModal
                            v-if="endCalendar"
                            :sel-date="endDate"
                            @onSelect="selectEndDate"
                            @onCancel="endCalendar = false"
                        />
                    </div>
                    <div class="flex lg:hidden">
                        <button
                            v-if="!isDatepicker"
                            class="focus:outline-none mt-4  mx-3"
                            @click="
                                ;[
                                    (this.$refs.period.value = 'custom'),
                                    (this.isDatepicker = true)
                                ]
                            "
                        >
                            <svg
                                v-if="!isDatepicker"
                                class="text-gray-400 hover:text-gray-500 h-10 w-10"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                />
                            </svg>
                        </button>
                        <div class="-mx-0 mx-5">
                            <label
                                for="period"
                                class="block ml-4 text-sm font-medium text-gray-700 text-left"
                                >{{ $t("bookings.period") }}</label
                            >
                            <select
                                name="period"
                                id="period"
                                ref="period"
                                @change="hideDatePicker"
                                class="mt-1 ml-4 block border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            >
                                <option value="all">{{
                                    $t("bookings.all")
                                }}</option>
                                <option value="last7">{{
                                    $t("bookings.last7")
                                }}</option>
                                <option value="last30">{{
                                    $t("bookings.last30")
                                }}</option>
                                <option
                                    v-for="(month, index) in pastYear"
                                    :value="
                                        month.year +
                                            '-' +
                                            (
                                                theMonths.indexOf(month.month) +
                                                1
                                            ).toLocaleString(undefined, {
                                                minimumIntegerDigits: 2
                                            })
                                    "
                                    :key="index"
                                    >{{
                                        month.month + " " + month.year
                                    }}</option
                                >
                                <!--                            <option value="2021-07">July, 2021</option>-->
                                <!--                            <option value="2021-06">June, 2021</option>-->
                                <!--                            <option value="2021-05">May, 2021</option>-->
                                <!--                            <option value="2021-04">April, 2021</option>-->
                                <!--                            <option value="2021-03">March, 2021</option>-->
                                <!--                            <option value="2021-02">February, 2021</option>-->
                                <!--                            <option value="2021-01">January, 2021</option>-->
                                <option disabled value="custom">{{
                                    $t("bookings.custom")
                                }}</option>
                                <!--                        <option value="custom">Custom Date</option>-->
                            </select>
                        </div>
                    </div>
                    <div class="hidden lg:flex">
                        <button
                            v-if="!isDatepicker"
                            class="focus:outline-none mt-16  mr-3"
                            @click="
                                ;[
                                    (this.$refs.period.value = 'custom'),
                                    (this.isDatepicker = true)
                                ]
                            "
                        >
                            <svg
                                v-if="!isDatepicker"
                                class="text-gray-400 hover:text-gray-500 h-10 w-10"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                />
                            </svg>
                        </button>
                        <div class="mt-10 -mx-0 mx-5">
                            <label
                                for="period"
                                class="block ml-3 text-sm font-medium text-gray-700 text-left"
                                >{{ $t("bookings.period") }}</label
                            >
                            <select
                                name="period"
                                id="period"
                                ref="period"
                                @change="hideDatePicker"
                                class="mt-1 block border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            >
                                <option value="all">{{
                                    $t("bookings.all")
                                }}</option>
                                <option value="last7">{{
                                    $t("bookings.last7")
                                }}</option>
                                <option value="last30">{{
                                    $t("bookings.last30")
                                }}</option>
                                <option
                                    v-for="(month, index) in pastYear"
                                    :value="
                                        month.year +
                                            '-' +
                                            (
                                                theMonths.indexOf(month.month) +
                                                1
                                            ).toLocaleString(undefined, {
                                                minimumIntegerDigits: 2
                                            })
                                    "
                                    :key="index"
                                    >{{
                                        month.month + " " + month.year
                                    }}</option
                                >
                                <!--                            <option value="2021-07">July, 2021</option>-->
                                <!--                            <option value="2021-06">June, 2021</option>-->
                                <!--                            <option value="2021-05">May, 2021</option>-->
                                <!--                            <option value="2021-04">April, 2021</option>-->
                                <!--                            <option value="2021-03">March, 2021</option>-->
                                <!--                            <option value="2021-02">February, 2021</option>-->
                                <!--                            <option value="2021-01">January, 2021</option>-->
                                <option disabled value="custom">{{
                                    $t("bookings.custom")
                                }}</option>
                                <!--                        <option value="custom">Custom Date</option>-->
                            </select>
                        </div>
                    </div>
                </div>
                <!-- Activity table (small breakopoint and up) -->
                <div class="block">
                    <div class="mx-auto">
                        <div class="flex flex-col mt-2">
                            <div
                                class="align-middle min-w-full overflow-x-auto border-b overflow-x-scroll"
                            >
                                <table
                                    class="table-auto min-w-full divide-y divide-gray-200"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("bookings.user") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{
                                                    $t("bookings.spending_plan")
                                                }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("bookings.venue") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{
                                                    $t("bookings.booking_type")
                                                }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider border-r"
                                            >
                                                {{ $t("bookings.booked_time") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider border-r"
                                            >
                                                {{ $t("bookings.check-in") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider border-r"
                                            >
                                                {{ $t("bookings.check-out") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{
                                                    $t("bookings.hours_booked")
                                                }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("bookings.amount") }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        class="bg-white divide-y divide-gray-200"
                                    >
                                        <tr
                                            class="bg-white text-left font-medium text-tiny"
                                            v-for="(booking, index) in this.data
                                                .list"
                                            :key="index"
                                        >
                                            <td class="border-r pl-4 py-3">
                                                {{ booking.username }}
                                                <div>{{ booking.email }}</div>
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ booking.plan_name }}
                                            </td>
                                            <td
                                                class="border-r whitespace-nowrap pl-4"
                                            >
                                                {{ booking.space }}
                                            </td>
                                            <td
                                                class="border-r whitespace-nowrap pl-4"
                                            >
                                                {{ booking.book_type }}
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ booking.book_time }}
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ booking.check_in }}
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ booking.check_out }}
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ booking.duration_hr }}
                                            </td>
                                            <td class="pl-4">
                                                {{ booking.amount }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="this.data.total_page >= 1"
                        class="mt-8 flex flex-wrap justify-center items-center"
                    >
                        <button
                            @click="
                                currentPage > 1
                                    ? [(currentPage -= 1), this.getBookings()]
                                    : ''
                            "
                            type="button"
                            class="px-6 py-1 border rounded-md"
                        >
                            {{ $t("button.prev_pg") }}
                        </button>
                        <span class="mx-4">
                            {{ $t("page1") }} {{ currentPage }}/{{
                                this.data.total_page
                            }}
                            {{ $t("page2") }}
                        </span>
                        <button
                            @click="
                                currentPage < this.data.total_page
                                    ? [(currentPage += 1), this.getBookings()]
                                    : ''
                            "
                            type="button"
                            class="px-6 py-1 border rounded-md"
                        >
                            {{ $t("button.next_pg") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isLoading" class="flex justify-center mt-20 items-center">
            <div class="w-1/5">
                <Spinner />
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from "@/components/Spinner"
import CalendarModal from "@/components/CalendarModal"
export default {
    name: "Bookings",
    components: { CalendarModal, Spinner },
    data() {
        return {
            searchTerm: "",
            data: {},
            csvData: [],
            csv: "",
            csvTotalPage: 1,
            isFileReady: false,
            isLoading: true,
            isDatepicker: false,
            startDate: this.getToday(),
            startCalendar: false,
            endDate: this.getToday(),
            endCalendar: false,
            isSearch: false,
            selectedVenue: "",
            selectedUser: "",
            selectedPeriod: "all",
            venueList: [],
            userList: [],
            pastYear: [],
            currentPage: 1,
            itemsPerPage: 10,
            date: "",
            theMonths: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ]
        }
    },
    props: { user: { required: false }, enterprise: { required: false } },
    computed: {
        now() {
            return new Date()
        },
        getUser() {
            return new Set(this.userList)
        }
    },
    watch: {
        $route(to, from) {
            if (to.params !== from.params) {
                this.isSearch = this.$route.params.isSearch
                this.searchTerm = this.$route.params.searchTerm
                if (this.$route.name === "Bookings") {
                    this.currentPage = 1
                    this.getBookings()
                }
            }
        },
        enterprise(to, from) {
            if (this.user.role === 1) {
                this.getBookings()
                this.getBookings(1).then(() => {
                    setTimeout(() => {
                        this.wholeList()
                    }, 500)
                    this.currentPage = 1
                })
            }
        }
    },
    mounted() {
        this.getBookings()
        this.getPastYear()
        this.getBookings(1).then(() => {
            setTimeout(() => {
                this.wholeList()
            }, 500)
        })
        this.isSearch = this.$route.params.isSearch
        this.searchTerm = this.$route.params.searchTerm
    },
    methods: {
        async getBookings(page) {
            const token = await this.$auth.getTokenSilently()
            if (page) {
                let url = ""
                if (this.user.role === 1) {
                    url =
                        process.env.VUE_APP_API_BASE_URL +
                        `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&period=all&enterprise_id=${this.enterprise}&page=` +
                        page
                } else {
                    url =
                        process.env.VUE_APP_API_BASE_URL +
                        `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&period=all&page=` +
                        page
                }
                this.$axios
                    .get(url, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(response => {
                        if (page === 1) {
                            this.csv =
                                "User name,Email,Space,Venue type,Book type,Booked time,Check-in time,Check-out time,Amount\n"
                            this.csvData = response.data.Data.list
                            this.csvData.forEach(row => {
                                this.csv +=
                                    row.username +
                                    "," +
                                    row.email +
                                    "," +
                                    row.space +
                                    "," +
                                    row.venue_type +
                                    "," +
                                    '"' +
                                    row.book_type +
                                    '"' +
                                    "," +
                                    '"' +
                                    row.book_time +
                                    '"' +
                                    "," +
                                    '"' +
                                    row.check_in +
                                    '"' +
                                    "," +
                                    '"' +
                                    row.check_out +
                                    '"' +
                                    "," +
                                    row.amount
                                this.csv += "\n"
                            })
                        } else {
                            response.data.Data.list.forEach(row => {
                                this.csvData.push(row)
                                this.csv +=
                                    row.username +
                                    "," +
                                    row.email +
                                    "," +
                                    row.space +
                                    "," +
                                    row.venue_type +
                                    "," +
                                    '"' +
                                    row.book_type +
                                    '"' +
                                    "," +
                                    '"' +
                                    row.book_time +
                                    '"' +
                                    "," +
                                    row.check_in +
                                    '"' +
                                    "," +
                                    '"' +
                                    row.check_out +
                                    '"' +
                                    "," +
                                    row.amount
                                this.csv += "\n"
                            })
                        }
                        this.csvTotalPage = response.data.Data.total_page
                    })
            } else {
                if (!this.isSearch && !this.isDatepicker) {
                    let url = ""
                    if (this.user.role === 1) {
                        url =
                            process.env.VUE_APP_API_BASE_URL +
                            `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&venue=${this.selectedVenue}&user_name=${this.selectedUser}&period=${this.selectedPeriod}&enterprise_id=${this.enterprise}&page=` +
                            this.currentPage
                    } else
                        url =
                            process.env.VUE_APP_API_BASE_URL +
                            `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&venue=${this.selectedVenue}&user_name=${this.selectedUser}&period=${this.selectedPeriod}&page=` +
                            this.currentPage
                    this.$axios
                        .get(url, {
                            headers: {
                                Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                            }
                        })
                        .then(response => {
                            this.data = response.data.Data
                            setTimeout(() => {
                                this.isLoading = false
                            }, 500)
                        })
                } else if (!this.isSearch && this.isDatepicker) {
                    if (this.startDate && this.endDate) {
                        if (this.endDate >= this.startDate) {
                            let url = ""
                            if (this.user.role === 1) {
                                url =
                                    process.env.VUE_APP_API_BASE_URL +
                                    `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&venue=${this.selectedVenue}&user_name=${this.selectedUser}&period=${this.selectedPeriod}&start_time=${this.startDate}&end_time=${this.endDate}&enterprise_id=${this.enterprise}&page=` +
                                    this.currentPage
                            } else
                                url =
                                    process.env.VUE_APP_API_BASE_URL +
                                    `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&venue=${this.selectedVenue}&user_name=${this.selectedUser}&period=${this.selectedPeriod}&start_time=${this.startDate}&end_time=${this.endDate}&page=` +
                                    this.currentPage
                            this.$axios
                                .get(url, {
                                    headers: {
                                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                                    }
                                })
                                .then(response => {
                                    this.data = response.data.Data
                                })
                        } else alert("End date must be after Start date")
                    }
                } else if (this.isSearch && !this.isDatepicker) {
                    let url = ""
                    if (this.user.role === 1) {
                        url =
                            process.env.VUE_APP_API_BASE_URL +
                            `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&venue=${this.selectedVenue}&user_name=${this.selectedUser}&period=${this.selectedPeriod}&q=${this.searchTerm}&enterprise_id=${this.enterprise}&page=` +
                            this.currentPage
                    } else
                        url =
                            process.env.VUE_APP_API_BASE_URL +
                            `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&venue=${this.selectedVenue}&user_name=${this.selectedUser}&period=${this.selectedPeriod}&q=${this.searchTerm}&page=` +
                            this.currentPage
                    this.$axios
                        .get(url, {
                            headers: {
                                Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                            }
                        })
                        .then(response => {
                            this.data = response.data.Data
                            setTimeout(() => {
                                this.isLoading = false
                            }, 500)
                        })
                } else {
                    if (this.startDate && this.endDate) {
                        if (this.endDate >= this.startDate) {
                            let url = ""
                            if (this.user.role === 1) {
                                url =
                                    process.env.VUE_APP_API_BASE_URL +
                                    `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&venue=${this.selectedVenue}&user_name=${this.selectedUser}&q=${this.searchTerm}&period=${this.selectedPeriod}&start_time=${this.startDate}&end_time=${this.endDate}&enterprise_id=${this.enterprise}&page=` +
                                    this.currentPage
                            } else
                                url =
                                    process.env.VUE_APP_API_BASE_URL +
                                    `/reservation/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&venue=${this.selectedVenue}&user_name=${this.selectedUser}&q=${this.searchTerm}&period=${this.selectedPeriod}&start_time=${this.startDate}&end_time=${this.endDate}&page=` +
                                    this.currentPage
                            this.$axios
                                .get(url, {
                                    headers: {
                                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                                    }
                                })
                                .then(response => {
                                    this.data = response.data.Data
                                })
                        }
                    }
                }
            }
        },
        isNumber(e) {
            let char = e.key
            if (/^[0-9]+$/.test(char)) return true
            else e.preventDefault()
        },
        totalPages(list) {
            return Math.ceil(list.length / this.itemsPerPage)
        },
        updateUser(user) {
            this.selectedUser = user
            this.currentPage = 1
            this.getBookings()
            // if (
            //     this.currentPage >
            //     this.totalPages(
            //         this.filterBookings(this.selectedUser, this.selectedVenue)
            //     )
            // ) {
            //     this.currentPage = 1
            // }
        },
        updateVenue(venue) {
            this.selectedVenue = venue
            this.currentPage = 1
            this.getBookings()
            // if (
            //     this.currentPage >
            //     this.totalPages(
            //         this.filterBookings(this.selectedUser, this.selectedVenue)
            //     )
            // ) {
            //     this.currentPage = 1
            // }
        },
        pagination(list, currentPage) {
            if (currentPage <= this.totalPages(list)) {
                let index = (currentPage - 1) * this.itemsPerPage
                return list.slice(index, index + this.itemsPerPage)
            }
        },
        // getVenueList() {
        //     for (let i = 0; i < this.bookingRecords.length; i++) {
        //         this.venueList.push(this.bookingRecords[i].workspace)
        //     }
        // },
        // getUserList() {
        //     for (let i = 0; i < this.bookingRecords.length; i++) {
        //         this.userList.push(this.bookingRecords[i].username)
        //     }
        // },
        getPastYear() {
            for (let i = 6; i > 0; i--) {
                let future = new Date(
                    this.now.getFullYear(),
                    this.now.getMonth() - i + 1,
                    1
                )
                let month = this.theMonths[future.getMonth()]
                let year = future.getFullYear()
                this.pastYear.push({ month, year })
            }
            this.pastYear.reverse()
        },
        async wholeList() {
            for (let i = 1; i <= this.csvTotalPage; i++) {
                await this.getBookings(i).then(res => {
                    if (i === this.csvTotalPage) {
                        setTimeout(() => {
                            this.isFileReady = true
                        }, 900)
                    }
                })
            }
        },
        async downloadCSVData() {
            const today = new Date()
            const date =
                today.getFullYear() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getDate()
            const anchor = document.createElement("a")
            this.csv = "\uFEFF" + this.csv
            anchor.href =
                "data:text/csv;charset=utf-8," + encodeURIComponent(this.csv)
            anchor.target = "_blank"
            anchor.download = `booking_records_${date}.csv`
            anchor.click()
        },
        isLetter(e) {
            let char = e.key
            if (/^[A-Za-z]+$/.test(char)) return true
            else e.preventDefault()
        },
        updatePeriod() {
            this.selectedPeriod = this.$refs.period.value
            this.currentPage = 1
            if (this.isDatepicker && this.startDate && this.endDate) {
                this.dateValidation()
            } else this.getBookings()
        },
        hideDatePicker() {
            if (this.isDatepicker) {
                this.isDatepicker = false
            }
            this.updatePeriod()
        },
        dateFormatting(e, type) {
            this.isNumber(e)
            if (
                type === "start" &&
                (this.startDate.length === 4 || this.startDate.length === 7)
            ) {
                this.startDate += "-"
            }
            if (
                type === "end" &&
                (this.endDate.length === 4 || this.endDate.length === 7)
            ) {
                this.endDate += "-"
            }
        },
        dateValidation() {
            let timestamp_start = Date.parse(this.startDate)
            let timestamp_end = Date.parse(this.endDate)

            if (
                isNaN(timestamp_start) === false &&
                isNaN(timestamp_end) === false
            ) {
                this.getBookings()
            } else alert("Invalid date")
        },
        getToday() {
            let d = new Date()
            let year = d.getFullYear()
            let month = d.getMonth() + 1
            month < 10 ? (month = "0" + month) : month
            let date = d.getDate()
            date < 10 ? (date = "0" + date) : date
            return `${year}-${month}-${date}`
        },
        selectStartDate(val) {
            this.startDate = val
            this.getBookings()
        },
        selectEndDate(val) {
            this.endDate = val
            this.getBookings()
        }
        // autofill() {
        //     if (this.isSearch) {
        //         if (
        //             this.venueList.find(a =>
        //                 a
        //                     .toLowerCase()
        //                     .startsWith(this.searchTerm.toLowerCase())
        //             )
        //         ) {
        //             this.$refs.venue.value = this.searchTerm
        //             this.updateVenue(this.searchTerm)
        //         }
        //         if (
        //             this.userList.find(a =>
        //                 a
        //                     .toLowerCase()
        //                     .startsWith(this.searchTerm.toLowerCase())
        //             )
        //         ) {
        //             this.$refs.user.value = this.searchTerm
        //             this.updateUser(this.searchTerm)
        //         }
        //     }
        // }
    }
}
</script>

<style scoped></style>
