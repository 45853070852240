<template>
    <div class="bg-gray-300 rounded-md overflow-hidden">
        <div class="flex items-center justify-between py-2 px-6">
            <div>
                <span
                    v-text="month_names[month]"
                    class="lg:text-lg font-bold text-gray-800"
                />
                <span
                    v-text="year"
                    class="ml-1 lg:text-lg text-gray-600 font-normal"
                />
            </div>
            <div class="border rounded-lg px-1 pt-1">
                <button
                    type="button"
                    class="
                        leading-none
                        rounded-lg
                        transition
                        ease-in-out
                        duration-100
                        inline-flex
                        cursor-pointer
                        hover:bg-gray-200
                        p-1
                        items-center
                        focus:outline-none
                    "
                    @click="changeMonth('prev')"
                >
                    <svg
                        class="h-6 w-6 text-gray-500 inline-flex leading-none"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </button>
                <div class="border-r inline-flex h-6"></div>
                <button
                    type="button"
                    class="
                        leading-none
                        rounded-lg
                        transition
                        ease-in-out
                        duration-100
                        inline-flex
                        items-center
                        cursor-pointer
                        hover:bg-gray-200
                        p-1
                        focus:outline-none
                    "
                    @click="changeMonth('next')"
                >
                    <svg
                        class="h-6 w-6 text-gray-500 inline-flex leading-none"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5l7 7-7 7"
                        />
                    </svg>
                </button>
            </div>
        </div>
        <div class="flex flex-wrap">
            <template v-for="(day, index) in days" :key="index">
                <div class="px-2 py-2 w-1/7">
                    <div
                        v-text="day"
                        class="
                            text-gray-600 text-sm
                            uppercase
                            tracking-wide
                            font-bold
                            text-center
                        "
                    ></div>
                </div>
            </template>
        </div>
        <div class="-mx-1 -mb-1">
            <div class="flex flex-wrap border-t border-l">
                <template v-for="(blankday, index) in blankdays" :key="index">
                    <div
                        class="
                            text-center
                            border-r border-b
                            px-4
                            pt-2
                            h-12
                            w-1/7
                        "
                    ></div>
                </template>
                <template
                    v-for="(date, dateIndex) in no_of_days"
                    :key="dateIndex"
                >
                    <div
                        class="
                            lg:px-4
                            pt-2
                            border-r border-b
                            relative
                            h-12
                            w-1/7
                            flex
                            justify-center
                        "
                    >
                        <button
                            @click="selectDate(date)"
                            v-text="date"
                            class="
                                focus:outline-none
                                inline-flex
                                w-6
                                h-6
                                items-center
                                justify-center
                                cursor-pointer
                                text-center
                                leading-none
                                rounded-full
                                transition
                                ease-linear
                                duration-300
                            "
                            :class="{
                                'bg-yellow-400 text-gray-100 hover:bg-yellow-300 hover:text-white':
                                    isToday(date) === true &&
                                    date !== selectedDate,
                                'text-gray-700 hover:bg-gray-500 hover:text-white':
                                    isToday(date) === false &&
                                    date !== selectedDate,
                                'bg-red-500 text-gray-100 hover:bg-red-400 hover:text-white':
                                    date === selectedDate
                            }"
                        ></button>
                        <div
                            v-if="
                                customDates &&
                                    customDates.includes(formatDate(date))
                            "
                            class="
                                absolute
                                bottom-dot
                                h-1.5
                                w-1.5
                                rounded-full
                                bg-red-500
                            "
                        >
                            &nbsp;
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Calendar",
    emits: ["onChange", "onLoad", "onSelect"],
    props: {
        customDates: { required: false, type: Array },
        selDate: { required: false, type: String }
    },
    data() {
        return {
            month_names: [
                this.$t("calendar.january"),
                this.$t("calendar.february"),
                this.$t("calendar.march"),
                this.$t("calendar.april"),
                this.$t("calendar.may"),
                this.$t("calendar.june"),
                this.$t("calendar.july"),
                this.$t("calendar.august"),
                this.$t("calendar.september"),
                this.$t("calendar.october"),
                this.$t("calendar.november"),
                this.$t("calendar.december")
            ],
            days: [
                this.$t("calendar.sun"),
                this.$t("calendar.mon"),
                this.$t("calendar.tue"),
                this.$t("calendar.wed"),
                this.$t("calendar.thu"),
                this.$t("calendar.fri"),
                this.$t("calendar.sat")
            ],
            month: "",
            year: "",
            no_of_days: [],
            blankdays: [],
            selectedDate: ""
        }
    },
    methods: {
        initDate() {
            let today = new Date()
            this.month = today.getMonth()
            this.year = today.getFullYear()
        },
        isToday(date) {
            const today = new Date()
            const d = new Date(this.year, this.month, date)
            return today.toDateString() === d.toDateString()
        },
        changeMonth(type) {
            this.selectedDate = ""
            if (type === "prev") {
                if (this.month === 0) {
                    this.month = 11
                    this.year--
                    this.getNoOfDays()
                } else {
                    this.month--
                    this.getNoOfDays()
                }
            }
            if (type === "next") {
                if (this.month === 11) {
                    this.month = 0
                    this.year++
                    this.getNoOfDays()
                } else {
                    this.month++
                    this.getNoOfDays()
                }
            }
            this.$emit("onChange", { year: this.year, month: this.month + 1 })
        },
        getNoOfDays() {
            let daysInMonth = new Date(this.year, this.month + 1, 0).getDate()
            let dayOfWeek = new Date(this.year, this.month).getDay()
            let blankdaysArray = []
            for (let i = 1; i <= dayOfWeek; i++) {
                blankdaysArray.push(i)
            }
            let daysArray = []
            for (let i = 1; i <= daysInMonth; i++) {
                daysArray.push(i)
            }
            this.blankdays = blankdaysArray
            this.no_of_days = daysArray
        },
        formatDate(D) {
            const y = this.year
            let m = this.month + 1
            let d = D
            if (m < 10) {
                m = `0${m}`
            }
            if (d < 10) {
                d = `0${d}`
            }
            return `${y}-${m}-${d}`
        },
        selectDate(date) {
            this.selectedDate = date
            this.$emit("onSelect", {
                year: this.year,
                month: this.month + 1,
                date: date
            })
        }
    },
    mounted() {
        this.initDate()
        this.getNoOfDays()
        if (this.selDate) {
            this.year = this.selDate.split("/")[0]
            this.month = this.selDate.split("/")[1] - 1
            this.getNoOfDays()
            this.selectDate(parseInt(this.selDate.split("/")[2]))
        }
        this.$emit("onLoad", { year: this.year, month: this.month + 1 })
    }
}
</script>

<style scoped></style>
