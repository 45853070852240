<template>
    <div class="loader flex justify-between">
        <div
            class="w-3 h-3 lg:w-7 lg:h-7 bg-yellow-300 rounded-full animate-loading"
        ></div>
        <div
            class="w-3 h-3 lg:w-7 lg:h-7 bg-yellow-300 rounded-full animate-loading"
        ></div>
        <div
            class="w-3 h-3 lg:w-7 lg:h-7 bg-yellow-300 rounded-full animate-loading"
        ></div>
        <div
            class="w-3 h-3 lg:w-7 lg:h-7 bg-yellow-300 rounded-full animate-loading"
        ></div>
        <div
            class="w-3 h-3 lg:w-7 lg:h-7 bg-yellow-300 rounded-full animate-loading"
        ></div>
    </div>
</template>

<script>
export default {
    name: "Spinner"
}
</script>

<style lang="css">
.loader div {
    animation-duration: 1200ms;
}
.loader div:first-child {
    animation-delay: 0.1s;
}
.loader div:nth-child(2) {
    animation-delay: 0.2s;
}
.loader div:nth-child(3) {
    animation-delay: 0.3s;
}
.loader div:nth-child(4) {
    animation-delay: 0.4s;
}
.loader div:nth-child(5) {
    animation-delay: 0.5s;
}
</style>
