<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div
        class="fixed z-50 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
    >
        <div
            class="
                flex
                items-end
                justify-center
                min-h-screen
                pt-4
                px-4
                pb-20
                text-center
                sm:block sm:p-0
            "
        >
            <!--
              Background overlay, show/hide based on modal state.

              Entering: "ease-out duration-300"
                From: "opacity-0"
                To: "opacity-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100"
                To: "opacity-0"
            -->
            <div
                class="
                    fixed
                    inset-0
                    bg-gray-500 bg-opacity-75
                    transition-opacity
                "
                aria-hidden="true"
            ></div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
                class="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
                >&#8203;</span
            >

            <!--
              Modal panel, show/hide based on modal state.

              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->
            <div
                class="
                    inline-block
                    align-bottom
                    bg-white
                    rounded-lg
                    px-4
                    pt-5
                    pb-4
                    text-left
                    overflow-hidden
                    shadow-xl
                    transform
                    transition-all
                    sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-4
                "
            >
                <div class="flex justify-end mb">
                    <button @click="onCancel">X</button>
                </div>
                <Calendar :sel-date="date" @onSelect="onSelect" />
            </div>
        </div>
    </div>
</template>

<script>
import Calendar from "@/components/Calendar"
export default {
    name: "CalendarModal",
    components: { Calendar },
    props: {
        selDate: { required: true, type: String }
    },
    data() {
        return {
            date: this.selDate.replaceAll("-", "/")
        }
    },
    emits: ["onCancel", "onSelect"],
    methods: {
        onCancel() {
            this.$emit("onCancel")
        },
        onSelect(val) {
            let date = `${val.year}-${
                val.month < 10 ? "0" + val.month : val.month
            }-${val.date < 10 ? "0" + val.date : val.date}`
            this.$emit("onSelect", date)
        }
    }
}
</script>

<style scoped></style>
